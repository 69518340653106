import {MassEditVideoInformation} from '../../../interfaces/videoMassEdit';

// eslint-disable-next-line import/prefer-default-export
export const defaults = {
    languageId: null,
    title: '',
    description: '',
    'upload.keywords': '',
    startDate: null,
    endDate: null,
    captionFile: '',
    'upload.filename': '',
    'upload.customId': '',
    'upload.customThumbnail': '',
    'upload.videoId': '',
    'upload.uploadPolicyId': null,
    'upload.categoryId': null,
    'upload.notifySubscribers': false,
    'upload.seriesGroupId': null,
    'upload.seasonSubgroupId': null,
} as MassEditVideoInformation;
