import {voMutationName, nonvoMutationName} from '../../configs/pages/videos/copy';
import validateYoutubeVideoTags from '../validators/youtubeVideoTags';
import validateYoutubeVideoId from '../validators/youtubeVideoId';
import {MassEditVideoInformation} from '../../interfaces/videoMassEdit';
import {DataGridValue} from '../../interfaces/dataGrid';
import {defaults} from '../../configs/pages/mass-edit/defaults';
import {MassEditBasicInfoVideoInformation} from '../../interfaces/videoMassEditBasicInfo';

// eslint-disable-next-line import/prefer-default-export
export const getVideoVersionLabel = (version: string | undefined) => {
    if (!version) {
        return '';
    }

    if (version === 'voiceOver') {
        return voMutationName;
    }

    if (version === 'translation') {
        return nonvoMutationName;
    }

    return '';
};

export const mutationPropertyValidation = (
    validate: {property: keyof MassEditVideoInformation; value: DataGridValue},
) => {
    const validationResult = {
        isValid: true,
        message: '',
    };

    if (defaults[validate.property] !== undefined && validate.value === defaults[validate.property]) {
        return validationResult;
    }

    if (typeof validate.value !== 'string') {
        validationResult.isValid = false;
        validationResult.message = 'Incorrect value';

        return validationResult;
    }

    switch (validate.property) {
        case 'title':

            if (validate.value.length > 100) {
                validationResult.isValid = false;
                validationResult.message = 'Title is too long (max 100 characters)';
                break;
            }

            if (!/^[^<>]*$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'You can\'t use characters "<" or ">" in a video title';
                break;
            }

            break;
        case 'name':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = 'It can\'t be longer then 255 characters';
                break;
            }

            break;
        case 'captionFile':
            if (!/^.*\.(srt)$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'Not valid subtitles file format (.srt only)';
                break;
            }

            break;

        case 'description': {
            const byteSize = new Blob([validate.value]).size;

            if (byteSize > 5000) {
                validationResult.isValid = false;
                validationResult.message = 'Description is too long';
                break;
            }

            if (!/^[^<>]*$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'You can\'t use characters "<" or ">" in a video description';
                break;
            }

            break;
        }
        case 'keywords': {
            const tagsValidation = validateYoutubeVideoTags(validate.value);

            if (tagsValidation !== true) {
                validationResult.isValid = false;
                validationResult.message = tagsValidation;
                break;
            }

            break;
        }
        case 'upload.videoId': {
            if (validate.value.length === 0) {
                break;
            }

            const videoIdValidation = validateYoutubeVideoId(validate.value);

            if (videoIdValidation !== true) {
                validationResult.isValid = false;
                validationResult.message = videoIdValidation;
                break;
            }

            break;
        }
        case 'upload.filename':
            if (!/^.*\.(mp4|mov|avi)$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'Not valid video file format (.mp4, .mov or .avi only)';
                break;
            }

            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = 'It can\'t be longer then 255 characters';
                break;
            }

            break;
        case 'upload.customId':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = 'It can\'t be longer then 255 characters';
                break;
            }

            break;
        case 'upload.customThumbnail':
            if (!/^.*\.(jpg|png|jpeg|gif)$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'Not valid image file format (.jpg, .jpeg, .png or .gif)';
            } else if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "Custom Thumbnail can't be longer than 255 characters";
            }

            break;
        case 'note':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = 'It can\'t be longer then 255 characters';
                break;
            }

            break;
        default:
            validationResult.message = 'No validation criteria';
    }

    return validationResult;
};

const videoPropertyValidation = (
    validate: {property: keyof MassEditBasicInfoVideoInformation; value: DataGridValue},
) => {
    const validationResult = {
        isValid: true,
        message: '',
    };

    if (defaults[validate.property] !== undefined && validate.value === defaults[validate.property]) {
        return validationResult;
    }

    if (typeof validate.value !== 'string') {
        validationResult.isValid = false;
        validationResult.message = 'Incorrect value';

        return validationResult;
    }
};