import {navigate, PageProps} from 'gatsby';
import {
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
    Column,
    keyColumn,
    CellComponent,
    checkboxColumn,
    createTextColumn,
} from 'react-datasheet-grid';
import clsx from 'clsx';
import {Operation} from 'react-datasheet-grid/dist/types';
import {
    BulkEditMutationInput,
    EditMutationsDocument,
    GetMassEditVideoMutationsDocument,
    GetMassEditVideoMutationsQuery,
    LanguageOrderBy,
    OrderWay,
} from '../../graphql-types';
import Layout from '../../components/layout/layout';
import Datagrid from '../../components/modules/dataGrid/dataGrid';
import {VideoIcon} from '../../components/core/icons';
import * as styles from './mass-edit.module.css';
import * as datagridStyles from '../../components/modules/dataGrid/dataGrid.module.css';
import selectColumn, {SelectColumnOption} from '../../components/modules/dataGrid/columns/selectColumn';
import datePickerColumn from '../../components/modules/dataGrid/columns/datePickerColumn';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datasheet-grid/dist/style.css';
import Stepper from '../../components/core/stepper/stepper';
import {Button, Tabs} from '../../components/core';
import toastify from '../../helpers/toast/toastify';
import {setIndex} from '../../helpers/objects';
import {MassEditVideoInformation} from '../../interfaces/videoMassEdit';
import {mutationPropertyValidation} from '../../helpers/pages/video';
import {
    DirtyField,
    MassEditBackendErrors,
} from '../../interfaces/general';
import {applyDefaultValuesFallback} from '../../helpers/pages/mass-edit';
import {getDirtyFieldsInArrayOfObjects} from '../../helpers/components/dataGrid';

const StatusColumn: CellComponent = () => (
    <div
        className={datagridStyles.statusColumn}
    >
        <VideoIcon/>
    </div>
);

const processData = (data: GetMassEditVideoMutationsQuery) => {
    if (!data) {
        return [];
    }

    return data.mutations.results.map(item => ({
        id: item.id,
        version: item.version,
        languageId: item.language?.id || null,
        title: item.title,
        description: item.description,
        'upload.keywords': item.upload?.keywords,
        startDate: item.startDate,
        endDate: item.endDate,
        captionFile: item.captionFile,
        'video.mcbt': item.video.mcbt,
        'video.theme.name': item.video.theme?.name,
        'upload.filename': item.upload?.filename,
        'upload.customId': item.upload?.customId,
        'upload.customThumbnail': item.upload?.customThumbnail,
        'upload.videoId': item.upload?.videoId,
        'upload.uploadPolicyId': item.upload?.uploadPolicy?.id || null,
        'upload.categoryId': item.upload?.category?.id || null,
        'upload.privacy': item.upload?.privacy,
        'upload.notifySubscribers': item.upload?.notifySubscribers,
        'upload.seriesGroupId': item.upload?.seriesGroup?.id || null,
        'upload.seasonSubgroupId': item.upload?.seasonSubgroup?.id || null,
    }));
};

interface LocationState {
    mutationIds: string[],
}

const disableIfTranslationVersion = ({rowData}: {rowData: MassEditVideoInformation}) => (rowData.version === 'translation');

const steps = [
    {
        title: 'Step 1',
    },
    {
        title: 'Step 2',
    },
    {
        title: 'Complete!',
    },
];

const initialBackendErrors = {errors: {}, relatedIds: {}};

const VideosMassEditPage = ({location}: PageProps<null, null, LocationState>) => {
    const [videoIds, setVideoIds] = useState<string[] | null>(null);
    const [getMutations, {data: mutationsData}] = useLazyQuery(GetMassEditVideoMutationsDocument);
    const [editMutations] = useMutation(EditMutationsDocument);
    const [data, setData] = useState<MassEditVideoInformation[]>([]);
    const [originalData, setOriginalData] = useState<MassEditVideoInformation[]>([]);
    const [dataIdIndexMap, setDataIdIndexMap] = useState<Record<string, number>>({});
    const [dirtyFields, setDirtyFields] = useState<DirtyField<MassEditVideoInformation>[]>([]);
    const [stepIndex, setStepIndex] = useState(0);
    const timelineSteps = steps.filter((_, index) => index !== steps.length - 1);
    const [updatedCells, setUpdatedCells] = useState<Record<string, number>>({});
    const [errorCells, setErrorCells] = useState<Record<string, number>>({});
    const [errorMessage, setErrorMessage] = useState('');
    const [forwardStepDisabled, setForwardStepDisabled] = useState(true);
    const [saving, setSaving] = useState(false);
    const [backendErrors, setBackendErrors] = useState<MassEditBackendErrors<MassEditVideoInformation>>(initialBackendErrors);

    const resolveCellClassName = useCallback((
        {rowData, rowIndex, columnId}:
        {rowData: MassEditVideoInformation; rowIndex: number; columnId?: string},
    ) => {
        const rowValues = Object.values(rowData);
        const nullContent = rowValues.filter(value => value === null);
        const emptyRow = nullContent.length === rowValues.length;

        if (emptyRow || !columnId) {
            return undefined;
        }

        if (dirtyFields[rowIndex] === undefined) {
            return undefined;
        }

        const hasError = dirtyFields[rowIndex][columnId as keyof MassEditVideoInformation]?.isValid === false
            || (backendErrors?.errors[rowData.id] !== undefined && backendErrors?.errors[rowData.id].some(one => one.field === columnId))
            || (backendErrors?.relatedIds[rowData.id] !== undefined && backendErrors?.relatedIds[rowData.id].some(one => one.field === columnId));

        if (hasError) {
            return datagridStyles.cellError;
        }

        if (dirtyFields[rowIndex][columnId as keyof MassEditVideoInformation]) {
            return datagridStyles.cellDirty;
        }

        return undefined;
    }, [dirtyFields, backendErrors]);

    const columns: Column<MassEditVideoInformation>[] = useMemo(() => [
        {
            ...keyColumn<MassEditVideoInformation, 'video.mcbt'>('video.mcbt', createTextColumn()),
            title: 'MCBT/AID',
            width: 3,
            minWidth: 200,
            disabled: true,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'video.theme.name'>('video.theme.name', createTextColumn()),
            title: 'Theme',
            width: 3,
            minWidth: 200,
            disabled: true,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'title'>('title', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Title',
            width: 15,
            minWidth: 600,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'description'>('description', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Description',
            width: 6,
            minWidth: 400,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.keywords'>('upload.keywords', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Tags',
            width: 6,
            minWidth: 600,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'languageId'>('languageId', selectColumn({options: mutationsData?.languages.map(lang => ({value: lang?.id, label: lang?.shortName})) as SelectColumnOption[]})),
            title: 'Language',
            width: 3,
            minWidth: 110,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'startDate'>('startDate', datePickerColumn({})),
            title: 'Start date',
            width: 3,
            minWidth: 200,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'endDate'>('endDate', datePickerColumn({})),
            title: 'End date',
            width: 3,
            minWidth: 200,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.uploadPolicyId'>('upload.uploadPolicyId', selectColumn({options: mutationsData?.uploadPolicyAttributes.map(uploadPolicy => ({value: uploadPolicy?.id, label: uploadPolicy?.name})) as SelectColumnOption[]}) as Partial<Column<never>>),
            title: 'Upload policy',
            width: 3,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.seriesGroupId'>('upload.seriesGroupId', selectColumn({options: mutationsData?.seriesGroupAttributes.map(seriesGroup => ({value: seriesGroup?.id, label: seriesGroup?.name})) as SelectColumnOption[]}) as Partial<Column<never>>),
            title: 'Series/Group',
            width: 3,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.seasonSubgroupId'>('upload.seasonSubgroupId', selectColumn({options: mutationsData?.seasonSubgroupAttributes.map(seasonSubgroup => ({value: seasonSubgroup?.id, label: seasonSubgroup?.name})) as SelectColumnOption[]}) as Partial<Column<never>>),
            title: 'Season/Subgroup',
            width: 3,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },

        {
            ...keyColumn<MassEditVideoInformation, 'captionFile'>('captionFile', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Caption file name',
            width: 6,
            minWidth: 400,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.filename'>('upload.filename', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Filename',
            width: 6,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.customThumbnail'>('upload.customThumbnail', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Custom thumbnail',
            width: 6,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.customId'>('upload.customId', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Custom ID',
            width: 6,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.videoId'>('upload.videoId', createTextColumn(
                {
                    continuousUpdates: false,
                },
            )),
            title: 'Video ID',
            width: 6,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.categoryId'>('upload.categoryId', selectColumn({options: mutationsData?.categoryAttributes.map(category => ({value: category?.id, label: category?.name})) as SelectColumnOption[]}) as Partial<Column<never>>),
            title: 'Category',
            width: 3,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.privacy'>('upload.privacy', selectColumn({options: mutationsData?.privacyAttributes.map(privacy => ({value: privacy?.name, label: `${privacy?.name.charAt(0).toUpperCase()}${privacy?.name.slice(1)}`})) as SelectColumnOption[]}) as Partial<Column<never>>),
            title: 'Privacy',
            width: 3,
            minWidth: 400,
            disabled: disableIfTranslationVersion,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<MassEditVideoInformation, 'upload.notifySubscribers'>('upload.notifySubscribers', checkboxColumn as Partial<Column<never>>),
            title: 'Notify subscribers',
            width: 3,
            minWidth: 100,
            // disabled: disableIfTranslationVersion,
            disabled: true,
            cellClassName: resolveCellClassName,
        },
    ], [mutationsData, resolveCellClassName]);

    useEffect(() => {
        if (!location?.state?.mutationIds) {
            navigate('/videos', {state: {toast: {type: 'warning', text: 'No video IDs passed'}}});

            return;
        }

        setVideoIds(location.state.mutationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!videoIds) {
            return;
        }

        getMutations({
            variables: {
                filter: {
                    mutationIds: videoIds,
                },
                paging: {
                    page: 1,
                    limit: videoIds.length,
                },
                languageListOrder: {by: LanguageOrderBy.ShortName, way: OrderWay.Asc},
            },
            onCompleted: result => {
                const processedData = processData(result) as MassEditVideoInformation[];

                setData(processedData);
                setOriginalData(processedData);
                setDirtyFields(new Array(processedData.length).fill({}));
            },
        });
    }, [getMutations, videoIds]);

    useEffect(() => {
        if (originalData.length === 0) {
            setBackendErrors(initialBackendErrors);

            return;
        }

        const beErrors = JSON.parse(JSON.stringify(initialBackendErrors)) as MassEditBackendErrors<MassEditVideoInformation>;
        const idIndexMap = {} as Record<string, number>;
        const changeCounts = {} as Record<string, number>;
        const errorCounts = {} as Record<string, number>;

        originalData.forEach((rowData, index) => {
            beErrors.errors[rowData.id] = [];
            idIndexMap[rowData.id] = index;
            changeCounts[rowData.id] = 0;
            errorCounts[rowData.id] = 0;
        });

        setBackendErrors(beErrors);
        setDataIdIndexMap(idIndexMap);
        setUpdatedCells(changeCounts);
        setErrorCells(errorCounts);
    }, [originalData, setDataIdIndexMap]);

    const stepBack = () => {
        if (stepIndex < 1) {
            setStepIndex(0);

            return;
        }

        setStepIndex(current => current - 1);
    };

    const saveChanges = () => {
        setSaving(true);

        editMutations({
            variables: {
                inputs: dirtyFields.reduce((result: BulkEditMutationInput[], dataRow: DirtyField<MassEditVideoInformation>, index) => {
                    const keys = Object.keys(dataRow) as Array<keyof MassEditVideoInformation>;

                    if (keys.length !== 0) {
                        result.push({
                            id: data[index].id,
                            input: keys.reduce((previous, key) => setIndex(previous, key, dataRow[key]?.value), {}),
                        });
                    }

                    return result;
                }, []),
            },
            onCompleted(editedResult) {
                if (!editedResult) {
                    toastify({type: 'error', text: 'There was an error saving the changes'});
                    setSaving(false);

                    return;
                }

                toastify({
                    type: 'success',
                    text: 'The changes were saved',
                });

                navigate('/videos');
            },
            onError(err) {
                const errorsObj = JSON.parse(JSON.stringify(initialBackendErrors)) as MassEditBackendErrors<MassEditVideoInformation>;

                err.graphQLErrors?.forEach(error => {
                    const errorItems = error.extensions.failedItems as {
                        message: string,
                        extensions: {
                            id: string,
                            duplicateId?: string,
                            input: keyof MassEditVideoInformation,
                        },
                    }[];

                    if (!errorItems) {
                        return;
                    }

                    errorItems.forEach(item => {
                        if (!item.extensions) {
                            return;
                        }

                        if (!Object.keys(errorsObj.errors).includes(item.extensions.id)) {
                            errorsObj.errors[item.extensions.id] = [];
                        }

                        errorsObj.errors[item.extensions.id].push({
                            field: item.extensions.input,
                            message: item.message,
                            value: data[(dataIdIndexMap as Record<string, number>)[item.extensions.id]][item.extensions.input],
                            relatedId: item.extensions.duplicateId,
                        });

                        if (!item.extensions.duplicateId) {
                            return;
                        }

                        if (!Object.keys(errorsObj.relatedIds).includes(item.extensions.duplicateId)) {
                            errorsObj.relatedIds[item.extensions.duplicateId] = [];
                        }

                        errorsObj.relatedIds[item.extensions.duplicateId].push({
                            id: item.extensions.id,
                            field: item.extensions.input,
                            value: data[(dataIdIndexMap as Record<string, number>)[item.extensions.duplicateId]][item.extensions.input],
                        });
                    });
                });
                setBackendErrors(errorsObj);
                setSaving(false);
                setStepIndex(0);

                toastify({type: 'error', text: err.message});
            },
            refetchQueries: ['getMassEditVideoMutations'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const stepForward = () => {
        if (stepIndex === steps.length - 1) {
            navigate('/videos');

            return;
        }

        if (stepIndex === 1) {
            saveChanges();

            return;
        }

        setStepIndex(current => current + 1);
    };

    const forwardButtonText = () => {
        switch (stepIndex) {
            case 0:
                return 'Continue';
            case 1:
                return `Yes, make ${Object.keys(updatedCells).reduce((previous, current) => previous + updatedCells[current], 0)} changes`;
            case 2:
                return 'Go to videos list';
            default:
                return 'Continue';
        }
    };

    useEffect(() => {
        const changes = Object.keys(updatedCells).reduce((previous, current) => previous + updatedCells[current], 0);
        const errors = Object.keys(errorCells).reduce((previous, current) => previous + errorCells[current], 0);

        if (errors > 0 || changes === 0) {
            setStepIndex(0);
            setForwardStepDisabled(true);

            return;
        }

        if (saving) {
            setForwardStepDisabled(true);

            return;
        }

        setForwardStepDisabled(false);
    }, [
        updatedCells,
        errorCells,
        saving,
    ]);

    useEffect(() => {
        const count = {} as Record<string, number>;
        const errors = {} as Record<string, number>;

        dirtyFields.forEach((row, index) => {
            const rowKeys = Object.keys(row) as Array<keyof MassEditVideoInformation>;

            count[originalData[index].id] = rowKeys.length;
            errors[originalData[index].id] = rowKeys.filter(key => row[key]?.isValid === false).length;
        });

        Object.keys(backendErrors.errors).forEach(rowId => {
            errors[rowId] += backendErrors.errors[rowId].length;
        });

        Object.keys(backendErrors.relatedIds).forEach(rowId => {
            errors[rowId] += backendErrors.relatedIds[rowId].length;
        });

        setUpdatedCells(count);
        setErrorCells(errors);
    }, [backendErrors, dirtyFields]);

    return (
        <Layout
            title="Video mass edit"
            scope="videos.write"
        >
            <Tabs
                data={[]}
            />
            <Stepper active={stepIndex} stepsArray={timelineSteps}/>
            <h2 className={clsx(styles.stepIndicator, stepIndex > 0 && styles.centered)}>
                {steps[stepIndex].title}
            </h2>
            {stepIndex === 0 && (
                <>
                    <div className={styles.statusBar}>
                        <div>Make and review the changes</div>
                        <div className={styles.changeCounter}>
                            {
                                Object.keys(updatedCells)
                                    .reduce((previous, current) => previous + updatedCells[current], 0) > 0
                                && `${Object.keys(updatedCells).reduce((previous, current) => previous + updatedCells[current], 0)} changes`
                            }
                        </div>
                        <div className={styles.errorMessage}>{errorMessage.length > 0 && errorMessage}</div>
                    </div>
                    <Datagrid<MassEditVideoInformation>
                        columns={columns}
                        data={data}
                        originalData={originalData}
                        onChange={(newValues: MassEditVideoInformation[], operations: Operation[]) => {
                            const fixedValues = applyDefaultValuesFallback(newValues);

                            setData(fixedValues);
                            getDirtyFieldsInArrayOfObjects(
                                fixedValues,
                                originalData,
                                dirtyFields,
                                setDirtyFields,
                                mutationPropertyValidation,
                                backendErrors,
                                setBackendErrors,
                                dataIdIndexMap,
                                operations,
                            );
                        }}
                        settings={{
                            rowHeight: 40,
                            rowCount: mutationsData?.mutations.results.length || 0,
                            gutterColumn: {
                                width: '0 0 50px',
                                minWidth: 50,
                                component: StatusColumn,
                            },
                        }}
                        dirtyFields={dirtyFields}
                        setDirtyFields={setDirtyFields}
                        backendErrors={backendErrors}
                        setBackendErrors={setBackendErrors}
                        updatedCells={updatedCells}
                        errorCells={errorCells}
                        idIndexMap={dataIdIndexMap}
                        setErrorMessage={setErrorMessage}
                        propertyValidation={mutationPropertyValidation}
                    />
                </>
            )}
            {stepIndex === 1 && (
                <div className={styles.summaryWrapper}>
                    <div className={styles.mainText}>
                        {`You are about to make ${
                            Object.keys(updatedCells)
                                .reduce((previous, current) => previous + updatedCells[current], 0)
                        } changes to videos`}
                    </div>
                    <div className={styles.secondaryText}>This might take a while, so please don&apos;t leave immediately once you hit that blue button.</div>
                    <div className={styles.note}>Now, do you really want to continue?</div>
                </div>
            )}
            {stepIndex === 2 && (
                <div className={styles.summaryWrapper}>
                    <div className={styles.mainText}>Lorem</div>
                    <div className={styles.secondaryText}>Ipsum</div>
                    <div className={clsx(styles.note, styles.success)}>All done.</div>
                </div>
            )}
            <div className={styles.buttonsWrapper}>
                {stepIndex > 0 && stepIndex < 2 && (
                    <Button
                        text="Back"
                        onClick={stepBack}
                        variant="outline"
                        size="medium"
                    />
                )}
                <Button
                    text={forwardButtonText()}
                    onClick={stepForward}
                    size="medium"
                    disabled={forwardStepDisabled}
                />
            </div>
        </Layout>
    );
};

export default VideosMassEditPage;
